* {
  //font-family: 'Lato', sans-serif;
}

body {
  font-size: 16px;
  height: 100%; /* needed for container min-height */
}

body.home-page {
  background: url('../../static/background-02.jpg') no-repeat top center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

#logo {
  display: block;
  float: left;
  clear: both;

  margin: 18px 0px;
  max-height: 60px;
  max-width: 214px;
}

body.home-page #logo {
  margin: 45px 0px 0px 30px;
  max-height: 80px;
  max-width: 286px;
}

#main-msg {
  color: #ffeb11;
  display: block;
  margin: 145px 0px 0px 85px;
  //font-size: 60pt;
  font-size: 32pt;
  clear: both;
  float: left;
}

$hoverColor: #ffeb11;

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

#footer div a:link,
#footer div a:visited {
  font-size: 14pt;
  color: white;
  padding: 10px 0px;
  display: block;
  text-decoration: none;
}

#footer div a:hover {
  color: #ffeb11;
}

#menu-item-1 {
  background-color: #489fbf;
}

#menu-item-2 {
  background-color: #a748bf;
}

#menu-item-3 {
  background-color: #bf7d48;
}

#sidebar {
  color: white;
  margin: 0px;
  padding: 0px;
}

#menu-principal {
  float: right;
  list-style: none;
  font-size: 11pt;
  margin: 0px;
  padding: 0px;
}

#menu-principal li {
  display: inline-block;
  padding: 0px 20px 0 0;
  text-align: right;
  line-height: 30pt;
  color: white;
}

.home-page #menu-principal li {
  display: block;
}

.content-page {

  #menu-principal {
    margin: 5px 0px;
  }

  #menu-cita {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

#menu-principal li a:visited,
#menu-principal li a:link {
  color: white;
  text-decoration: none;
}

#menu-principal li a:hover {
  color: #ffeb11;
  //font-weight: bold;
}

#menu-idioma {
  font-size: 9pt;

  a.active {
    color: #ffeb11 !important;
  }

}

#menu-cita {
  padding: 10px;
  display: block;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: rgb(221, 80, 60);
  background: linear-gradient(148deg, rgba(221, 80, 60, 1) 0%, rgba(212, 109, 95, 1) 100%);
}

#menu-principal li#menu-cita {
  line-height: 20pt;
  padding: 5px 20px 5px 10px;
}

#menu-cita span {
  font-size: 14pt;
  color: #ffeb11;
}

#menu-contacto {
  margin-bottom: 20px;
  margin-right: 10px;
}

#menu-contacto a:link,
#menu-contacto a:visited {
  color: white;
  font-size: 16pt;
  border: 1px solid white;
  border-radius: 10px;
  text-align: center;
  display: block;
  padding: 5px 0px;
}

#menu-contacto a:hover {
  color: #ffeb11;
  border: 1px solid #ffeb11;
}

// content

.content-page {

  #conte-header {
    background-color: #5d707f;
  }

  #page-contenido {
    color: #405f7b;
    padding-top: 10px;
    //padding-bottom: 30px;
    padding-bottom: 60px;
    display: block;
  }

  #page-contenido p span.emergencia {
    color: #88535d;
  }

  #page-contenido h1 {
    border-left: 4px solid #bf7d49;
    padding: 10px 0px 0px 10px;
  }

  #page-contenido h1 i {
    color: #c3d1dd;
  }

  #page-contenido h1 i:hover {
    color: #a4b8c9;
  }

  .page-sidebar-item {
    border-left: 3px solid #edc2bb;
    margin-bottom: 15px;
    font-size: 11pt;
  }

  .page-sidebar-item h2 {
    color: #a9aeb3;
    font-size: 15pt;
  }

  .page-sidebar-list {
    list-style-type: none;
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  .page-sidebar-list li a:link,
  .page-sidebar-list li a:visited {
    color: #3c3a3a;
    text-decoration: none;
    padding: 5px 0px;
    display: block;
    width: 100%;
    border: 1px solid white;
    padding-left: 10px;
  }

  .page-sidebar-list li a:hover {
    background-color: #eceef0;
    border: 1px solid #999999;
    border-radius: 10px;
  }

  .page-sidebar-item p {
    font-size: 12pt;
    text-align: center
  }

  .page-sidebar-item p span {
    font-size: 18pt;
    font-weight: bold;
  }

  .page-sidebar-item p span.credito {
    clear: both;
    float: right;
    font-size: 12pt;
    font-style: italic;
    color: #999999;
  }

  #items-contacto {
    background-color: #a8a8a8;
  }

  #menu-contacto {
    margin: 0;
  }

  #menu-contacto a:link,
  #menu-contacto a:visited {
    border: 0;
  }

  #menu-contacto a:hover {
    border: 0;
    color: black;
  }

  .cont-group {
    background-color: #eceef0;
    border: 1px solid #999999;
    border-radius: 10px;
    padding: 3px;
  }

  .group {
    text-align: center;
    border-radius: 10px;
    padding: 3px;
  }

  .group:hover {
    cursor: pointer;
    background-color: #c4cdd1;
  }

  .cal-no:hover {
    background-color: #eceef0;
    cursor: auto;
  }

  .sel-group,
  .sel-group:hover {
    background-color: #6a8196;
    border: 1px solid #405f7b;
    color: white;
    cursor: auto;
  }

  .cal-d {
    border-right: 2px solid #c4cdd1;
  }

  .row-form {
    padding-top: 10px;
  }

  .row-form:last-child {
    padding-bottom: 10px;
  }

  #plus-cal {
    font-size: 30pt;
    color: #8397aa;
  }

  .frm-input {

    &:focus {
      outline: none;
    }

    margin: 5px 0px;
    width: 100%;
    display: block;
    background-color: #eceef0;
    border: 1px solid #999999;
    border-radius: 10px;
    padding: 10px;
  }

  .no-padd {
    padding: 0px 0px 0px 10px;
  }

  .frm-btn {
    border-radius: 10px;
    background-color: #6a8196;
    border: 1px solid #405f7b;
    color: white;
    width: 100%;
    padding: 10px 0px;
  }

  .frm-btn:hover {
    background-color: #999999;
    color: white;
  }

  #recursos-tabla tbody tr th a:link,
  #recursos-tabla tbody tr th a:visited {
    font-size: 20pt;
    color: #405f7b;
  }

  #recursos-tabla tbody tr th a:hover {
    color: #999999;
  }

  #footer div a:hover {
    color: black;
  }

}

.form-error, .invalid-feedback {
  color: #bf7d49;
}