//@import "~lato-font/fonts/lato-black";
@import "~bootstrap/scss/bootstrap";
@import "~jquery-datetimepicker/build/jquery.datetimepicker.min.css";
@import "~toastr/toastr";
//@import "app/style.css";
//@import "app/contenido.css";
//@import "app/mediac.css";
//@import "custom/general";
//@import "custom/header";
//@import "custom/styles";
//@import "app/style.css";
//@import "app/mediac.css";
@import "common/loding";
@import "custom/style";
@import "custom/general";
@import "custom/mediac";

//@import "app/mediac.css";

//body {
//  background-color: lightgray;
//}