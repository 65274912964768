.padd-5 {
  padding: 5px;
}

.red {
  border: 1px solid red;
}

.yellow {
  border: 1px solid yellow;
}

.blue {
  border: 1px solid blue;
}

.green {
  border: 1px solid green;
}

.hid-r {
  height: 0px;
  overflow: hidden;
  opacity: 0;
}