/*Extra small devices (portrait phones, less than 576px) */
$xs-devices: 575.98px;

@media (max-width: $xs-devices) {

  body.home-page {
    background: url('../../static/mobile-background-02.jpg') no-repeat top center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }

  #logo {
    float: none;
    margin: 18px auto !important;
  }

  #conte-header {
    padding: 0;
  }

  #menu-contacto {
    display: none;
  }

  #main-msg {
    display: none;
  }

  #footer div a:link,
  #footer div a:visited {
    font-size: 11pt;
  }

  #menu-principal {
    float: none;
    margin: 0px auto !important;
  }

  #menu-principal li {
    text-align: center;
    display: block;
    line-height: 20pt;
  }

  #menu-cita {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  #page-sidebar {
    margin-bottom: 150px;
  }

  #cont-per-fecha-hora {
    display: block;
  }

  #cont-fecha-hora {
    display: none;
  }
}

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {

  body.home-page {
    background: url('../../static/mobile-background-02.jpg') no-repeat top center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }

  #logo {
    //border: 1px solid blue;
    margin: 18px 0px 0px 0px;
    max-height: 70px;
    max-width: 224px;
  }

  #menu-contacto {
    display: none;
  }

  #main-msg {
    margin: 55px 0px 0px 25px;
    font-size: 25pt;
  }

  #footer div a:link,
  #footer div a:visited {
    font-size: 11pt;
  }

  #page-sidebar {
    margin-bottom: 60px;
  }

  .page-sidebar-item {
    border-left: 6px solid #edc2bb;
  }

  #cont-per-fecha-hora {
    display: block;
  }

  #cont-fecha-hora {
    display: none;
  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {

  #logo {
    //border: 1px solid yellow;
  }

  #main-msg {
    font-size: 32pt;
  }

  #menu-contacto a:link,
  #menu-contacto a:visited {
    padding: 0px 0px;
  }

  .padd-5 {
    padding: 2px;
  }

  #footer div a:link,
  #footer div a:visited {
    font-size: 12pt;
  }

  #cont-per-fecha-hora {
    display: block;
  }

  #cont-fecha-hora {
    display: none;
  }

  .frm-label {
    display: none;
  }

  .group i {
    display: none;
  }

}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {

  #logo {
    //border: 1px solid greenyellow;
  }

  #menu-principal {
    margin: 5px 0px;
  }

  #menu-principal li {
    padding: 0px 10px 0 0;
  }

  #footer div a:link,
  #footer div a:visited {
    font-size: 11pt;
  }

}


